









import { Component, Prop, Vue } from "vue-property-decorator";
import { formatDate } from "@/lib/date";
import { OrganizationConsumption } from "@/models/organization";
import XChart from "../hoc/Chart.vue";

@Component({
  components: { XChart },
})
class PaymentChart extends Vue {
  @Prop({ required: true })
  readonly consumptionList!: OrganizationConsumption[];
  @Prop({ required: true }) readonly consumptionVolumeType!: number;

  fontFamily = "Roboto";
  fontSize = 14;

  // @ts-ignore
  labelColor = this.$vuetify.theme.currentTheme.chart.darken1;
  // @ts-ignore
  fontColor = this.$vuetify.theme.currentTheme.chart.lighten2;
  // @ts-ignore
  gridLines = this.$vuetify.theme.currentTheme.chart.lighten3;
  // @ts-ignore
  backgroundColor = this.$vuetify.theme.currentTheme.chart.lighten1;
  // @ts-ignore
  borderColor = this.$vuetify.theme.currentTheme.chart.lighten4;
  // @ts-ignore
  bodyFontColor = this.$vuetify.theme.currentTheme.chart.darken2;
  // @ts-ignore
  accruedDataColor = this.$vuetify.theme.currentTheme.chart.darken3;
  // @ts-ignore
  accruedDataBackgroundColor = this.$vuetify.theme.currentTheme.chart.lighten5;
  // @ts-ignore
  consumptionDataBackgroundColor = this.$vuetify.theme.currentTheme.chart.lighten4;
  // @ts-ignore
  consumptionDataColor = this.$vuetify.theme.currentTheme.chart.darken4;

  // Легенда
  legendProps = {
    labels: {
      fontColor: this.labelColor,
      fontFamily: this.fontFamily,
      fontSize: this.fontSize,
    },
  };

  // Ось X и ось Y
  axesCommonProps = {
    scaleLabel: {
      display: true,
      fontSize: this.fontSize,
      fontFamily: this.fontFamily,
      fontColor: this.labelColor,
    },
    ticks: {
      fontSize: this.fontSize,
      fontFamily: this.fontFamily,
      fontColor: this.fontColor,
    },
  };

  scalesProps = {
    xAxes: [
      {
        gridLines: { display: false },
        ticks: this.axesCommonProps.ticks,
        scaleLabel: {
          ...this.axesCommonProps.scaleLabel,
          labelString: "Период",
        },
      },
    ],
    yAxes: [
      {
        gridLines: { borderDash: [12], color: this.gridLines },
        ticks: this.axesCommonProps.ticks,
        scaleLabel: {
          ...this.axesCommonProps.scaleLabel,
          labelString: "Объем",
        },
      },
    ],
  };

  // Всплывающая подсказка
  tooltipGaps = { rowGap: 8, padding: 16 };

  tooltipProps = {
    // Содержание и позиционирование
    mode: "index",
    position: "nearest",
    // Внешний вид контейнера
    backgroundColor: this.backgroundColor,
    borderColor: this.borderColor,
    borderWidth: 1,
    cornerRadius: 8,
    // Заголовок
    titleFontColor: this.labelColor,
    titleFontFamily: this.fontFamily,
    titleFontSize: this.fontSize,
    titleMarginBottom: this.tooltipGaps.rowGap,
    // Тело
    bodyFontColor: this.bodyFontColor,
    bodyFontFamily: this.fontFamily,
    bodySpacing: this.tooltipGaps.rowGap,
    // Указатель
    caretPadding: 8,
    caretSize: 16,
    // Отступы
    xPadding: this.tooltipGaps.padding,
    yPadding: this.tooltipGaps.padding,
  };

  cssRules = { position: "relative", height: "500px", width: "100%" };

  public get preparedConsumptionList() {
    type PreparedConsumption = {
      datesInterval: string[];
      volume: number[];
    };

    const { consumptionList, consumptionVolumeType } = this;
    const requiredKeyByIndex = consumptionVolumeType ? "сумма" : "объем";

    let preparedConsumptionList: PreparedConsumption = {
      datesInterval: [],
      volume: [],
    };

    return consumptionList
      .slice()
      .reverse()
      .reduce(
        (
          acc: PreparedConsumption,
          consumptionByMonth: OrganizationConsumption
        ) => {
          acc.datesInterval.push(
            formatDate(consumptionByMonth["месяц"], "monthAndYear")
          );

          acc.volume.push(consumptionByMonth[requiredKeyByIndex]);

          return acc;
        },
        preparedConsumptionList
      );
  }

  public get chartData() {
    const { consumptionVolumeType, preparedConsumptionList } = this;
    const { datesInterval, volume } = preparedConsumptionList;

    const { label, borderColor, backgroundColor } = [
      {
        label: "Потреблено",
        backgroundColor: this.consumptionDataBackgroundColor,
        borderColor: this.consumptionDataColor,
        // borderColorSecondary: "#0000ff",
      },
      {
        label: "Начислено",
        backgroundColor: this.accruedDataBackgroundColor,
        borderColor: this.accruedDataColor,
      },
    ][consumptionVolumeType];

    const datasets = [
      {
        label,
        borderColor,
        backgroundColor,
        borderWidth: 2,
        fill: true,
        pointStyle: "circle",
        pointRadius: 4,
        data: volume,
      },
    ];

    return { labels: datesInterval, datasets };
  }

  public get chartOptions() {
    return {
      responsive: true,
      maintainAspectRatio: false,
      legend: this.legendProps,
      scales: this.scalesProps,
      tooltips: this.tooltipProps,
    };
  }
}

export default PaymentChart;
